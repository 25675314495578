import {Injectable, signal, WritableSignal} from '@angular/core';
import {tap} from 'rxjs';
import {RadiologyTaskHistoryDto} from 'src/app/interfaces/backend/dto/radiology-task-history-dto.intrerface';
import {TableRowExtended} from 'src/app/interfaces/core/table-row-extended.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {generateRandomString} from 'src/app/utilities/string.util';

@Injectable({providedIn: 'root'})
export class RadiologyTaskHistoryFacade {
    public dataSignal: WritableSignal<TableRowExtended<RadiologyTaskHistoryDto>[]> = signal([]);

    constructor(
        private radiologyControllerService: RadiologyControllerService
    ) {
    }

    public reset() {
        this.dataSignal.set([]);
    }

    public loadData(radiologyTaskId: number) {
        this.loadDataLogic(radiologyTaskId).subscribe();
    }

    public loadDataLogic(radiologyTaskId: number) {
        return this.radiologyControllerService.getRadiologyTaskHistory(radiologyTaskId).pipe(
            tap(data => this.dataSignal.set(data.map((entry) => {
                const enableTableFeatures = Boolean(entry.htmlDescription);
                const result: TableRowExtended<RadiologyTaskHistoryDto> = {
                    ...entry,
                    dataKey: generateRandomString(6),
                    rowClickable: enableTableFeatures,
                    hasSelection: enableTableFeatures
                };

                return result;
            })))
        );
    }
}