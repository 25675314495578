import {EnvironmentInjector, Injectable, signal, WritableSignal} from '@angular/core';
import {RadNoteDto} from 'src/app/interfaces/backend/dto/rad-note-dto.interface';
import {RadiologyControllerService} from 'src/app/services/backend/radiology-controller.service';
import {displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class RadiologyTaskNotesFacade {
    public dataSignal: WritableSignal<RadNoteDto[]> = signal([]);

    constructor(
        private radiologyControllerService: RadiologyControllerService,
        private injector: EnvironmentInjector
    ) {
    }

    public reset() {
        this.dataSignal.set([]);
    }

    public loadData(radiologyTaskId: number) {
        this.getData(radiologyTaskId).subscribe(data => this.dataSignal.set(data));
    }

    public getData(radiologyTaskId: number) {
        return this.radiologyControllerService.getRadiologyTaskNotes(radiologyTaskId);
    }

    public createRadiologyTaskNote(radiologyTaskId: number, radNoteDto: RadNoteDto, callback?: () => void) {
        this.radiologyControllerService.createRadiologyTaskNote(radiologyTaskId, radNoteDto).subscribe(() => {
            displaySuccess(this.injector);
            callback?.();
        });
    }
}