import {Injectable, Type} from '@angular/core';
import {AdditionalElement} from 'src/app/interfaces/core/additional-element.interface';

@Injectable({
    providedIn: 'root'
})
export class BottombarService {
    public bottombarElements: AdditionalElement[] = [];
    public component: Type<any> | null = null;
    public componentInputs: Record<string, unknown> = {};

    public reset() {
        this.bottombarElements = [];
        this.component = null;
        this.componentInputs = {};
    }
}